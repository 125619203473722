.header {
  & .userprodropd {
    top: 0rem;
    position: relative;
    text-align: right;
    right: -1rem;

    ul {
      li {
        list-style-type: none;
        display: inline-block;
        margin-right: 0px;

        &.nav-item.dropdown.userprodropdlist {
          span {
            &#navbardrop {
              padding: 0.5rem 0.1rem !important;
              float: right;

            }

            &,
            &:hover,
            &.nav-link.dropdown-toggle {
              color: #31719b;
            }

            & span.profile_img_bg.dark_color_text {
              padding: 2.8px 9px 10px !important;
              font-size: 17px;
              background: #f2f2f2;
              border-radius: 50%;
            }
          }
        }

        & .dropdown-menu.show {
          background: #fff;
          border-radius: 4px;
          box-shadow: 0 0 0 1px rgba(111, 119, 130, .15), 0 5px 20px 0 rgba(21, 27, 38, .08);
          box-sizing: border-box;
          border-radius: 1px;
          font-size: 14px;
          margin-top: 43px;
          border-radius: 0;
          max-width: 100%;
          right: 0;

          & .dropdown-divider {
            height: 0;
            margin: .3rem 0 !important;
            overflow: hidden;
            border-top: 1px solid #e9ecef;
          }

          & span.logicntopm {
            max-width: 100%;
            float: right;
            margin-top: 1px;
          }

          & span.activeusrp {
            svg {
              height: 10px;
              width: 10px;
              top: 3px;
              position: relative;
              color: #4caf50;
              padding-right: 0.5rem;
              float: right;
            }
          }

          span {
            padding: 0px 7px 6px 7px;
          }
  

          & .dropdown-item.profileusr {
            span {
              &.black_color_text.usertextoverflow {
                max-width: 105px;
                overflow: hidden;
                text-overflow: ellipsis;
                word-wrap: break-word;
                cursor: pointer;
                display: flex;
                align-items: center;
              }

              &.activeusrp {
                color: #4caf50 !important;
                font-size: 9px !important;
                float: right;
                margin-top: 2px !important;

              }

            }
          }

          &.prousersigupicon {
            float: right;
            height: 14px;
            width: 14px;
            top: 3px;
            position: relative;
          }
          button.dropdown-item.logdisinline.btn.btn-primary {
            padding: 0.2rem 0.5rem;
          }
        }
      }
    
      
span.black_color_text.usertextoverflow {
      max-width: 105px;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
      cursor: pointer;
      white-space: nowrap;
      display: inline-block;
    }
    span.activeusrp {
        position: relative;
        top: 2px;
    }
    
    }


  }




  .cursor_pointer {
    cursor: pointer;
  }

  .dropdown-item .logout-icon {
    margin-left: auto;
    /* Push the icon to the right */
  }

  @media only screen and (min-width: 913px) and (max-width: 1023px) {
    li.nav-item.dropdown.userprodropdlist {
      top: 0rem !important;
      right: 0rem;
      left: -0.3rem;
    }
  }

  @media only screen and (min-width: 1024px) and (max-width: 1170px) {
    li.nav-item.dropdown.userprodropdlist {
      top: 0rem !important;
      right: 0rem;
      left: -0.3rem;
    }
  }

  @media (min-width: 821px) {
    .title h1 {
      font-size: 1.8rem;
    }
  }

  @media (min-width: 1400px) {
    .title h1 {
      font-size: 2rem;
    }
  }

  @media only screen and (min-width: 821px) and (max-width: 912px) {
    li.nav-item.dropdown.userprodropdlist {
      top: 0rem !important;
      right: 0rem;
      left: -0.3rem;
    }
  }
}
