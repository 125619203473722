body {
    margin: 0;
    padding: 0;
    list-style-type: none;

    font-weight: 600 !important;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif !important;
}

html,
body {
    &::-webkit-scrollbar {
        display: none;
    }

    .k-grid{
    color: #606467 !important;
    font-weight: 600 !important;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif !important;
    }
    .k-drawer-item .k-item-text{
        white-space: normal
    }
}

h1, h2, h3, h4, h5 {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
}

@media (max-width:1024px) {
    body{
        min-width: 8in !important;
        overflow-x: auto !important;
        
    }
    td{
        width: 100px !important
    }
}

@import "./styles/main.scss";
