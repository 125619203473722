body {
  background-color: #ffffff !important;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  font-weight: 6s00 !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}
header {
  &.sd_partneradmin_headersection {
    position: sticky;
    top: 0;
    z-index: 10;
    box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
    background-color: #fff;
    nav {
      &.navbar {
        padding: 0px 0px;
      }
    }
    ul {
      li {
        list-style-type: none;
        display: inline-block;
      }
    }
    li {
      &.nav-item {
        &.dropdown {
          &.profileuserpaddtop {
            top: -7px;
          }
        }
      }
    }
    span {
      &.profile_imgbgclr {
        padding: 9px 11px 9px 11px;
        color: #31719b;
        background-color: #f2f2f2;
        border-radius: 50%;
        svg {
          position: relative;
          top: -2px;
          left: 1px;
        }
      }
      &.activeuserpro {
        svg {
          height: 10px;
          width: 10px;
          float: right;
          top: 3px;
          position: relative;
          display: inline-flex;
          color: #4caf50;
          float: right;
          padding-right: 0.5rem;
        }
      }
      &.prousersigupicon {
        float: right;
        height: 14px;
        width: 14px;
        top: 1px;
        position: relative;
      }
    }
    .dropdown-menu {
      &.show {
        &.profileuserdropdshow {
          background: #fff;
          border-radius: 4px;
          box-shadow: 0 0 0 1px rgba(111, 119, 130, 0.15),
            0 5px 20px 0 rgba(21, 27, 38, 0.08);
          box-sizing: border-box;
          border-radius: 1px;
          font-size: 14px;
          font-weight: 600;
          margin-top: 6px;
          border-radius: 0;
          max-width: 100%;
          right: 0;
          left: auto;
        }
      }
    }
  }
}
.header-sticky {
  position: fixed;
  top: 78px;
  left: 25%;
  z-index: 99992;
  width: 50%;
  text-align: center;
}
.dropdown-menu.profileuserdropdshow.show span.black_color_text.usertextoverflow {
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  cursor: pointer;
  white-space: nowrap;
  display: inline-block;
}
span.activeuserpro {
  position: relative;
  top: 5px;
}

.header-sticky.locktrial_alrtbox {
  font-size: 14px;
  font-weight: 600;
}

span {
  &.sd_partneradmin_boxalign {
    right: 0;
    left: 0rem;
    position: relative;
  } 

  &.sd_partneradmin_sync_status_partner {
    text-align: right;
    color: rgb(49, 113, 155);
    font-size: 12px;
    font-weight: 600;
    // margin-top: 5px;
    padding-right: 0rem;
  }
}
svg:not(:host).svg-inline--fa,
svg:not(:root).svg-inline--fa {
  overflow: visible;
  box-sizing: content-box;
}
.svg-inline--fa {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}
.refrsh_sync {
  margin: 0 8px;
  cursor: pointer;
  color: #31719b;
}


span.sd_clientname_overflowtext {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space:nowrap;
}
span.sd_clientnameuerrole_overflowtext {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space:nowrap;
}
button.dropdown-item.btn.btn-primary.sd_userdroplist_textfs:hover {
  border-radius: 0px;
}

.userprodropd {
  top: 0rem;
  position: relative;
  text-align: left;
  right: 2rem;

  ul {
    li {
      list-style-type: none;
      display: inline-block;
      margin-right: 0px;

      &.nav-item.dropdown.userprodropdlist {
        a {
          &#navbardrop {
            padding: 0.5rem 0.1rem !important;
            float: right;
          }

          &,
          &:hover,
          &.nav-link.dropdown-toggle {
            color: #31719b;
          }

          & span.profile_img_bg.dark_color_text {
            padding: 2.8px 9px 10px !important;
            font-size: 17px;
            background: #f2f2f2;
            border-radius: 50%;
          }
        }
      }

      & .dropdown-menu.show {
        background: #fff;
        border-radius: 4px;
        box-shadow: 0 0 0 1px rgba(111, 119, 130, 0.15),
          0 5px 20px 0 rgba(21, 27, 38, 0.08);
        box-sizing: border-box;
        border-radius: 1px;
        font-size: 14px;
        margin-top: 43px;
        border-radius: 0;
        max-width: 100%;
        right: 0;

        & .dropdown-divider {
          height: 0;
          margin: 0.3rem 0 !important;
          overflow: hidden;
          border-top: 1px solid #e9ecef;
        }

        & span.logicntopm {
          max-width: 100%;
          float: right;
          margin-top: 1px;
        }

        & .dropdown-item.profileusr {
          span {
            &.black_color_text.usertextoverflow {
              max-width: 105px;
              overflow: hidden;
              text-overflow: ellipsis;
              word-wrap: break-word;
              cursor: pointer;
              display: inline-block;
            }

            &.activeusrp {
              color: #4caf50 !important;
              font-size: 9px !important;
              float: right;
              margin-top: 2px !important;
            }
          }
        }
        button.dropdown-item.logdisinline.btn.btn-primary {
          padding: 0.2rem 0.5rem;
        }
      }
    }
  }
  @media only screen and (min-width: 913px) and (max-width: 1023px) {
    li.nav-item.dropdown.userprodropdlist {
      top: 0rem !important;
      right: 0rem;
      left: -0.3rem;
    }
  }

  @media only screen and (min-width: 1024px) and (max-width: 1170px) {
    li.nav-item.dropdown.userprodropdlist {
      top: 0rem !important;
      right: 0rem;
      left: -0.3rem;
    }
  }

  @media (min-width: 821px) {
    .title h1 {
      font-size: 1.8rem;
    }
  }

  @media (min-width: 1400px) {
    .title h1 {
      font-size: 2rem;
    }
  }
  @media only screen and (min-width: 821px) and (max-width: 912px) {
    li.nav-item.dropdown.userprodropdlist {
      top: 0rem !important;
      right: 0rem;
      left: -0.3rem;
    }
  }
}

.userprodropdlist {
  top: 0rem;
  position: relative;
  text-align: right;

  ul {
    li {
      list-style-type: none;
      display: inline-block;
      margin-right: 0px;

      &.nav-item.dropdown.userprodropdlist {
        span {
          &#navbardrop {
            padding: 0.5rem 0.1rem !important;
            float: right;
          }

          &,
          &:hover,
          &.nav-link.dropdown-toggle {
            color: #31719b;
          }

          & span.profile_img_bg.dark_color_text {
            padding: 2.8px 9px 10px !important;
            font-size: 17px;
            background: #f2f2f2;
            border-radius: 50%;
          }
        }
      }

      & .dropdown-menu.show {
        background: #fff;
        border-radius: 4px;
        box-shadow: 0 0 0 1px rgba(111, 119, 130, 0.15),
          0 5px 20px 0 rgba(21, 27, 38, 0.08);
        box-sizing: border-box;
        border-radius: 1px;
        font-size: 14px;
        margin-top: 43px;
        border-radius: 0;
        max-width: 100%;
        right: 0;

        & .dropdown-divider {
          height: 0;
          margin: 0.3rem 0 !important;
          overflow: hidden;
          border-top: 1px solid #e9ecef;
        }

        & span.logicntopm {
          max-width: 100%;
          float: right;
          margin-top: 1px;
        }

        & .dropdown-item.profileusr {
          span {
            &.black_color_text.usertextoverflow {
              max-width: 105px;
              overflow: hidden;
              text-overflow: ellipsis;
              word-wrap: break-word;
              cursor: pointer;
              display: inline-block;
            }

            &.activeusrp {
              color: #4caf50 !important;
              font-size: 9px !important;
              float: right;
              margin-top: 2px !important;
            }
          }
        }
        button.dropdown-item.logdisinline.btn.btn-primary {
          padding: 0.2rem 0.5rem;
        }
      }
    }
  }
  @media only screen and (min-width: 913px) and (max-width: 1023px) {
    li.nav-item.dropdown.userprodropdlist {
      top: 0rem !important;
      right: 0rem;
      left: -0.3rem;
    }
  }

  @media only screen and (min-width: 1024px) and (max-width: 1170px) {
    li.nav-item.dropdown.userprodropdlist {
      top: 0rem !important;
      right: 0rem;
      left: -0.3rem;
    }
  }

  @media (min-width: 821px) {
    .title h1 {
      font-size: 1.8rem;
    }
  }

  @media (min-width: 1400px) {
    .title h1 {
      font-size: 2rem;
    }
  }
  @media only screen and (min-width: 821px) and (max-width: 912px) {
    li.nav-item.dropdown.userprodropdlist {
      top: 0rem !important;
      right: 0rem;
      left: -0.3rem;
    }
  }
}

.cursor_pointer{
  cursor: pointer;
}
